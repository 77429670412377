// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-gatsby-templates-city-page-tsx": () => import("./../../../src/gatsby/templates/city-page.tsx" /* webpackChunkName: "component---src-gatsby-templates-city-page-tsx" */),
  "component---src-gatsby-templates-form-page-tsx": () => import("./../../../src/gatsby/templates/form-page.tsx" /* webpackChunkName: "component---src-gatsby-templates-form-page-tsx" */),
  "component---src-gatsby-templates-page-collection-item-tsx": () => import("./../../../src/gatsby/templates/page-collection-item.tsx" /* webpackChunkName: "component---src-gatsby-templates-page-collection-item-tsx" */),
  "component---src-gatsby-templates-page-collection-tsx": () => import("./../../../src/gatsby/templates/page-collection.tsx" /* webpackChunkName: "component---src-gatsby-templates-page-collection-tsx" */),
  "component---src-gatsby-templates-page-tsx": () => import("./../../../src/gatsby/templates/page.tsx" /* webpackChunkName: "component---src-gatsby-templates-page-tsx" */),
  "component---src-gatsby-templates-sitemap-tsx": () => import("./../../../src/gatsby/templates/sitemap.tsx" /* webpackChunkName: "component---src-gatsby-templates-sitemap-tsx" */),
  "component---src-gatsby-templates-store-page-tsx": () => import("./../../../src/gatsby/templates/store-page.tsx" /* webpackChunkName: "component---src-gatsby-templates-store-page-tsx" */),
  "component---src-pages-buildinfo-tsx": () => import("./../../../src/pages/buildinfo.tsx" /* webpackChunkName: "component---src-pages-buildinfo-tsx" */)
}

