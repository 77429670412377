/** If on Safari/IE, intersection observer polyfill may be needed. */
export const onClientEntry = () => {
  if (!('IntersectionObserver' in window)) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    import('intersection-observer')
  }

  if (!('fetch' in window)) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    import('isomorphic-fetch')
  }

  if (!('URL' in window) || !('URLSearchParams' in window)) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    import('url-polyfill')
  }
}

export const onRouteUpdate = () => {
  const wnd = window as any
  
  if(wnd.addUtm){
    wnd.addUtm()
  }
  if(wnd.runPixel){
    wnd.runPixel()
  }
  if(wnd.runBing){
    wnd.runBing()
  }
  }
